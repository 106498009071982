import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

export const Button = (props) => {
  return (
    <Link
      to={`${props.link}`}
      className={`button-parent ${props.className || ""}`}
    >
      <div className="button-item">
        <p className="btn-2">{props.text}</p>
      </div>
    </Link>
  );
};
