import React from "react";
import image from "../assets/img/404.svg";
import { Button } from "../components/Button";

const Page404 = () => {
  return (
    <section className="page-404">
      <div className="container">
        <div className="page-404__body">
          <div className="page-404__image">
            <img src={image} alt="Page not found" />
          </div>
          <p className="page-404__text">
            The page you are looking for is not available
          </p>
          <Button link="/" text="go to home" />
        </div>
      </div>
    </section>
  );
};

export default Page404;
